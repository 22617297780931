import { useAuthUser } from './useAuth';
import {
  ArticleIcon,
  AssignmentIndIcon,
  CalendarViewDayIcon,
  CalendarViewMonthIcon,
  CloudSyncIcon,
  ContactMailIcon,
  ContactPageIcon,
  ContentPasteSearchIcon,
  DesktopWindowsRoundedIcon,
  EngineeringIcon,
  EventNoteIcon,
  GroupsIcon,
  LiveHelpIcon,
  MailIcon,
  PedalBikeIcon,
  PeopleIcon,
  PersonIcon,
  RvHookupIcon,
  StorageIcon,
  TuneIcon,
  UploadFileRoundedIcon,
} from '../icons/material';
import {
  ControlPanelRoute,
  ExchangeRoute,
  MailingRoute,
  MaintainerRoute,
  MetbusAppRoute,
  RosterRoute,
  UserManagerRoute,
} from '../types/appRoutes';
import { SystemClaims } from '../types/claims/claimsApp';
import {
  MinimumPermission,
  PermissionLevel,
} from '../types/claims/profilePermission';
import { SystemModules } from '../types/claims/userManagerModules';
import { selectPermissions } from '../utils/selectPermissions';

export interface AppModule<T> {
  title: string;
  route: T;
  icon: JSX.Element;
  minimunPermission?: MinimumPermission;
}
export interface AppSection<T> {
  title: string;
  icon: JSX.Element;
  modules: AppModule<T>[];
}
interface SidebarOption {
  metbusApps: AppModule<MetbusAppRoute>;
  metbusDocs: AppModule<MetbusAppRoute>;
  roster?: AppSection<RosterRoute>;
  exchanges?: AppModule<ExchangeRoute>;
  maintainers?: AppSection<MaintainerRoute>;
  control?: AppSection<ControlPanelRoute>;
  userManager?: AppSection<UserManagerRoute>;
  mailing?: AppSection<MailingRoute>;
}

const modulesMap = new Map<
  | RosterRoute
  | ExchangeRoute
  | MaintainerRoute
  | ControlPanelRoute
  | MailingRoute
  | UserManagerRoute,
  SystemModules
>([
  ['/roster/vista-semanal', 'RosterVistaSemanal'],
  ['/roster/vista-mensual', 'RosterVistaMensual'],
  ['/roster/carga-de-programacion', 'RosterCargaDeProgramacion'],
  ['/roster/resumen-trabajado', 'RosterResumenTrabajado'],
  ['/intercambios', 'Exchanges'],
  ['/mantenedores/feriados', 'HolidayMaintainer'],
  // ['/control/historial-procesos', 'ControlTaskHistory'],
  ['/control/actualizaciones', 'ControlUpdater'],
  ['/user-manager/usuarios', 'UserMaintainer'],
  ['/user-manager/sistemas', 'SystemMaintainer'],
  ['/user-manager/perfiles', 'ProfileMaintainer'],
  ['/mailing/acciones', 'ActionMaintainer'],
  ['/mailing/destinatarios', 'RecipientMaintainer'],
  ['/mailing/grupos', 'GroupMaintainer'],
  ['/mailing/configuraciones', 'ConfigurationMaintainer'],
]);

export const useSidebarSections = (): SidebarOption => {
  const user = useAuthUser();
  const metbusApps: SidebarOption['metbusApps'] = {
    title: 'Aplicaciones Metbus',
    icon: <LiveHelpIcon />,
    route: '/aplicaciones-metbus',
  };

  const metbusDocs: SidebarOption['metbusApps'] = {
    title: 'Documentación',
    icon: <ArticleIcon />,
    route: '/docs',
  };

  if (!user?.systemClaims)
    return {
      metbusApps,
      metbusDocs,
    };

  const roster: SidebarOption['roster'] = {
    title: 'Asistencia',
    icon: <EventNoteIcon />,
    modules: [],
  };

  const weekView: AppModule<RosterRoute> = {
    title: 'Vista Semanal',
    route: '/roster/vista-semanal',
    icon: <CalendarViewDayIcon />,
    minimunPermission: MinimumPermission.ReadOnly,
  };

  const monthView: AppModule<RosterRoute> = {
    title: 'Vista Mensual',
    route: '/roster/vista-mensual',
    icon: <CalendarViewMonthIcon />,
    minimunPermission: MinimumPermission.ReadOnly,
  };

  const scheduleLoad: AppModule<RosterRoute> = {
    title: 'Carga de Programación',
    route: '/roster/carga-de-programacion',
    icon: <UploadFileRoundedIcon />,
    minimunPermission: MinimumPermission.ReadAndWrite,
  };

  const workedSummary: AppModule<RosterRoute> = {
    title: 'Resumen Trabajado',
    route: '/roster/resumen-trabajado',
    icon: <ContactPageIcon />,
    minimunPermission: MinimumPermission.ReadOnly,
  };

  const rosterModules = [
    weekView,
    monthView,
    scheduleLoad,
    workedSummary,
  ].filter((rosterModule) => {
    const permissionsRequired: PermissionLevel[] | undefined =
      selectPermissions(rosterModule?.minimunPermission);
    return (
      Object.keys(user.systemClaims as SystemClaims) as Array<
        keyof SystemClaims
      >
    ).find((moduleName) => {
      return (
        moduleName === modulesMap.get(rosterModule.route) &&
        (user.systemClaims as SystemClaims)[moduleName] &&
        permissionsRequired?.includes(
          (user.systemClaims as SystemClaims)[moduleName]
        )
      );
    });
  });

  roster.modules = rosterModules;

  const exchanges: SidebarOption['exchanges'] = {
    title: 'Intercambios entre Terminales',
    icon: <RvHookupIcon />,
    route: '/intercambios',
    minimunPermission: MinimumPermission.ReadOnly,
  };

  const exchangesPermission =
    selectPermissions(exchanges?.minimunPermission)?.includes(
      user.systemClaims.Exchanges
    ) ?? false;

  const maintainers: SidebarOption['maintainers'] = {
    title: 'Mantenedores',
    icon: <StorageIcon />,
    modules: [],
  };

  const holidays: AppModule<MaintainerRoute> = {
    title: 'Feriados',
    route: '/mantenedores/feriados',
    icon: <PedalBikeIcon />,
    minimunPermission: MinimumPermission.ReadOnly,
  };

  const maintainersModules = [holidays].filter((maintainersModule) => {
    const permissionsRequired: PermissionLevel[] | undefined =
      selectPermissions(maintainersModule?.minimunPermission);
    return (
      Object.keys(user.systemClaims as SystemClaims) as Array<
        keyof SystemClaims
      >
    ).find(
      (moduleName) =>
        moduleName === modulesMap.get(maintainersModule.route) &&
        (user.systemClaims as SystemClaims)[moduleName] &&
        permissionsRequired?.includes(
          (user.systemClaims as SystemClaims)[moduleName]
        )
    );
  });

  maintainers.modules = maintainersModules;

  const controlPanel: SidebarOption['control'] = {
    title: 'Panel de Control',
    icon: <EngineeringIcon />,
    modules: [],
  };

  // const taskHistory: AppModule<ControlPanelRoute> = {
  //   title: 'Procesos en segundo plano',
  //   route: '/control/historial-procesos',
  //   icon: <MemoryIcon />,
  //   minimunPermission: MinimumPermission.SuperUser,
  // };

  const dbUpdater: AppModule<ControlPanelRoute> = {
    title: 'Actualizar Base de Datos',
    route: '/control/actualizaciones',
    icon: <CloudSyncIcon />,
    minimunPermission: MinimumPermission.SuperUser,
  };

  const controlPanelModules = [dbUpdater].filter((controlPanelModule) => {
    const permissionsRequired: PermissionLevel[] | undefined =
      selectPermissions(controlPanelModule?.minimunPermission);
    return (
      Object.keys(user.systemClaims as SystemClaims) as Array<
        keyof SystemClaims
      >
    ).find(
      (moduleName) =>
        moduleName === modulesMap.get(controlPanelModule.route) &&
        (user.systemClaims as SystemClaims)[moduleName] &&
        permissionsRequired?.includes(
          (user.systemClaims as SystemClaims)[moduleName]
        )
    );
  });

  controlPanel.modules = controlPanelModules;

  const userManager: SidebarOption['userManager'] = {
    title: 'Manejo de Usuarios',
    icon: <PeopleIcon />,
    modules: [],
  };

  const userMaintainer: AppModule<UserManagerRoute> = {
    title: 'Usuarios',
    route: '/user-manager/usuarios',
    icon: <PersonIcon />,
    minimunPermission: MinimumPermission.ReadOnly,
  };

  const systemMaintainer: AppModule<UserManagerRoute> = {
    title: 'Sistemas',
    route: '/user-manager/sistemas',
    icon: <DesktopWindowsRoundedIcon />,
    minimunPermission: MinimumPermission.SuperUser,
  };

  const profileMaintainer: AppModule<UserManagerRoute> = {
    title: 'Perfiles',
    route: '/user-manager/perfiles',
    icon: <TuneIcon />,
    minimunPermission: MinimumPermission.SuperUser,
  };

  const userManagerModules = [
    userMaintainer,
    systemMaintainer,
    profileMaintainer,
  ].filter((userManagerModule) => {
    const permissionsRequired: string[] | undefined = selectPermissions(
      userManagerModule?.minimunPermission
    );
    return (
      Object.keys(user.systemClaims as SystemClaims) as Array<
        keyof SystemClaims
      >
    ).find(
      (moduleName) =>
        moduleName === modulesMap.get(userManagerModule.route) &&
        (user.systemClaims as SystemClaims)[moduleName] &&
        permissionsRequired?.includes(
          (user.systemClaims as SystemClaims)[moduleName]
        )
    );
  });

  userManager.modules = userManagerModules;

  const mailing: SidebarOption['mailing'] = {
    title: 'Manejo de Correos',
    icon: <MailIcon />,
    modules: [],
  };

  const actionMaintainer: AppModule<MailingRoute> = {
    title: 'Acciones',
    route: '/mailing/acciones',
    icon: <ContentPasteSearchIcon />,
    minimunPermission: MinimumPermission.SuperUser,
  };

  const recipientMaintainer: AppModule<MailingRoute> = {
    title: 'Destinatarios',
    route: '/mailing/destinatarios',
    icon: <ContactMailIcon />,
    minimunPermission: MinimumPermission.SuperUser,
  };

  const groupMaintainer: AppModule<MailingRoute> = {
    title: 'Grupos',
    route: '/mailing/grupos',
    icon: <GroupsIcon />,
    minimunPermission: MinimumPermission.SuperUser,
  };

  const configurationMaintainer: AppModule<MailingRoute> = {
    title: 'Configuraciones',
    route: '/mailing/configuraciones',
    icon: <AssignmentIndIcon />,
    minimunPermission: MinimumPermission.SuperUser,
  };

  const mailingModules = [
    actionMaintainer,
    recipientMaintainer,
    groupMaintainer,
    configurationMaintainer,
  ].filter((mailingModule) => {
    const permissionsRequired: string[] | undefined = selectPermissions(
      mailingModule?.minimunPermission
    );
    return (
      Object.keys(user.systemClaims as SystemClaims) as Array<
        keyof SystemClaims
      >
    ).find(
      (moduleName) =>
        moduleName === modulesMap.get(mailingModule.route) &&
        (user.systemClaims as SystemClaims)[moduleName] &&
        permissionsRequired?.includes(
          (user.systemClaims as SystemClaims)[moduleName]
        )
    );
  });

  mailing.modules = mailingModules;

  return {
    metbusApps,
    metbusDocs,
    roster: roster.modules.length > 0 ? roster : undefined,
    exchanges: exchangesPermission ? exchanges : undefined,
    maintainers: maintainers.modules.length > 0 ? maintainers : undefined,
    control: controlPanel.modules.length > 0 ? controlPanel : undefined,
    userManager: userManager.modules.length > 0 ? userManager : undefined,
    mailing: mailing.modules.length > 0 ? mailing : undefined,
  };
};
